<template>
  <div>
    <div class="top-banner">
      <div class="bg"></div>
      <div class="ban">
        <div class="title-big">American Delivery Service </div>
        <div class="title-small">
          <div>Experience hassle-free, timely delivery of your products.
          </div>
          <div>We allow you to add at most 200 packages in one order. </div>
        </div>
      </div>
    </div>
    <div class="cont">
      <a-row class="grid-demo" :gutter="[48, 48]">
        <a-col :span="8" v-for="item in data" :key="item.id">
          <div class="goDetali" @click="$router.push('/knowledge/blogdetail?id=' + item.id)">
            <div class="picture">
              <img :src="item.img" alt="">
              <div class="mask">
                <div class="icon-link">
                  <icon-link />
                </div>
              </div>
            </div>
            <h2>{{ item.title }}</h2>
          </div>
          <!-- <div class="time">{{ item.date }}</div> -->
          <p>
            {{ item.desc }}
          </p>
        </a-col>
      </a-row>
      <div class="page">
        <a-pagination :total="totalPage" @change="pageChange" :page-size="pageSize">
          <!-- <template #page-item-step="{ type }">
            <span v-if="type === 'previous'">Previous</span>
            <span v-if="type === 'next'">Next</span>
          </template> -->
        </a-pagination>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { IconLink } from '@arco-design/web-vue/es/icon'
import { articleLists } from '@/api/homepage.js'
import { Notification } from '@arco-design/web-vue'
const totalPage = ref(1)
const curPage = ref(1)
const pageSize = ref(6)
const data = ref([])
const pageChange = (current) => {
  curPage.value = current
  getInfo()
}

const getInfo = async () => {
  const res = await articleLists({
    page: curPage.value
  })
  if (res.code === 0) {
    totalPage.value = res.data.total
    curPage.value = Number(res.data.current_page)
    data.value = res.data.data
  } else {
    Notification.error(res.message)
  }
}

getInfo()
</script>

<style lang="less" scoped>
.page {
  display: flex;
  justify-content: center;
  margin-top: 60px;

  .arco-pagination {
    background-color: var(--color-fill-1);
    border-radius: 24px;
    padding: 8px 16PX;
  }
}

.top-banner {
  box-sizing: border-box;
  position: relative;
  height: 538px;
  overflow: hidden;

  .bg {
    background-image: url('../../../assets/images/home/cangku13.png');
    background-size: cover;
    background-position: 50% 50%;
    height: 540px;
    filter: blur(2px);
  }

  .ban {
    width: 700px;
    position: absolute;
    z-index: 9;
    top: 50%;
    right: 50%;
    transform: translate(50%, -70%);
  }

  .title-big {
    font-weight: 500;
    font-size: 56px;
    color: #fff;
    text-align: center;
    line-height: 64px;
    padding-top: 166px;
  }

  .title-small {
    margin-top: 28px;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    line-height: 28px;
    color: #fff;
  }
}

.cont {
  width: 1300px;
  margin: 0 auto;
  padding: 60px 40px;
  box-sizing: border-box;
  line-height: 1.8;

  .goDetali {
    .picture {
      position: relative;
      width: 375px;
      height: 360px;
      overflow: hidden;
      border-radius: 8px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        border: 1px solid var(--color-neutral-1);
      }

      .mask {
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(19, 185, 102, 0.7);
        width: 100%;
        height: 100%;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        display: none;
      }

      h2 {
        color: var(--color-text-1);
      }
    }

    &:hover {
      color: rgb(var(--primary-6));
      cursor: pointer;
      overflow: hidden;

      img {
        transform: scale(1.1, 1.1);
        transition: all 0.3s ease-in-out;
      }

      .mask {
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon-link {
          width: 40px;
          height: 40px;
          background-color: rgb(var(--primary-1));
          line-height: 40px;
          text-align: center;
          border-radius: 50%;
          color: var(--color-text-2);
        }
      }
    }
  }

  .time {
    font-weight: 600;
    font-size: 14px;
    color: var(--color-text-2);
  }

  p {
    color: var(--color-text-2);
    margin-top: 0;
  }
}
</style>
